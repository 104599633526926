<template>
  <v-row no-gutters justify="center">
    <v-col xs="12" sm="10" md="6"> <!-- идеальное поведение-->
      <formUserInfo/>
    </v-col>
    <v-col xs="12" sm="10" md="8">
      <table_test1/>
    </v-col>
    <v-col xs="12" sm="10" md="8">

    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
    table_test1: () => import("@/site/modules/test/table-test1"),
    formUserInfo: () => import("@/site/modules/test/old/form-userinfo"),
  }
}
</script>

<style scoped>

</style>